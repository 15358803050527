import React from 'react'
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "@/components/ui/card"
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"
import { Button } from "@/components/ui/button"
import { CalendarIcon, Clock3Icon } from 'lucide-react'
import { NavLink } from 'react-router-dom'

interface BlogOverviewCardProps {
  title: string
  excerpt: string
  author: {
    name: string
    avatar: string
  }
  date: string
  imageUrl: string
  slug: string
}

export function BlogOverviewCard({
  title,
  excerpt,
  author,
  date,
  imageUrl,
  slug
}: BlogOverviewCardProps) {
  return (
    <Card className="overflow-hidden">
      <div className="relative h-56 w-full">
        <img src={imageUrl} alt={title} className="object-cover w-full h-full" />
      </div>
      <CardHeader>
        <CardTitle className="line-clamp-2">{title}</CardTitle>
      </CardHeader>
      <CardContent>
        <p className="text-muted-foreground line-clamp-3 mb-4">{excerpt}</p>
        <div className="grid grid-cols-2 space-x-2">
          <div className='flex items-center space-x-2'>
            <Avatar>
              <AvatarImage src={author.avatar} alt={author.name} />
              <AvatarFallback>{author.name.charAt(0)}</AvatarFallback>
            </Avatar>
            <div>
              <p className="text-sm font-medium">{author.name}</p>
              <div className="flex items-center text-xs text-muted-foreground">
                <CalendarIcon className="mr-1 h-3 w-3" />
                <span>{date}</span>
              </div>
            </div>
          </div>
          <NavLink to={`/blog/${slug}`}>
            <Button className="w-full">Full Blog</Button>
          </NavLink>
        </div>
      </CardContent>
    </Card>
  )
}