import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { TypographyH3 } from "@/components/ui/typography/TypographyH1"
import { NavLink } from "react-router-dom"

export default function ContactUs() {
  return (
    <>
      <section className="py-10 bg-gray-50 sm:pt-16 lg:pt-24" id="contact">
    <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div className="grid grid-cols-2 md:col-span-3 lg:grid-cols-6 gap-y-16 gap-x-12">
            <div className="col-span-2 md:col-span-3 lg:col-span-2 lg:pr-8" style={{color: '#212121'}}>
                <TypographyH3>
                    <img src="/logo-removebg-preview.png" alt="Smler" width="120" height="32"/>
                </TypographyH3>

                <p className="text-base leading-relaxed text-gray-600 mt-7">A no-nonsense, reliable URL shortener for fast, simple link management. Fast performance and uptime guaranteed.</p>
                <p className="text-base leading-relaxed text-gray-600 mt-2">Smler is a product of Bokimo Technologies LLP</p>
                <p className="text-base leading-relaxed text-gray-600 mt-2">GSTIN: 27ABCFB1366M1ZB</p>

            </div>

            <div>
                <p className="text-sm font-semibold text-black tracking-widest uppercase">Site Links</p>

                <ul className="mt-6 space-y-4">

                    <li>
                        <NavLink to="/terms-and-condition" className="flex text-base text-black transition-all duration-200 hover:text-blue-600 focus:text-blue-600"> Terms & Conditions </NavLink>
                    </li>

                    <li>
                        <NavLink to="/privacy-policy" className="flex text-base text-black transition-all duration-200 hover:text-blue-600 focus:text-blue-600"> Privacy Policy </NavLink>
                    </li>
                    <li>
                        <NavLink to="/cancellations" className="flex text-base text-black transition-all duration-200 hover:text-blue-600 focus:text-blue-600"> Cancellations </NavLink>
                    </li>
                    <li>
                        <NavLink to="/support" className="flex text-base text-black transition-all duration-200 hover:text-blue-600 focus:text-blue-600"> Contact Us </NavLink>
                    </li>
                </ul>
            </div>

            <div className="col-span-2 md:col-span-1 lg:col-span-3 lg:pl-8" style={{color: '#212121'}}>
                <TypographyH3>Contact Us</TypographyH3>
                <p className="text-base leading-relaxed text-gray-600 mt-7">For further inquiries or support, reach out to us at <a className="text-blue-700 underline" href="mailto:support@smler.in">support@smler.in</a> or <a href="tel:9066645227" className="text-blue-700 underline">+91 90666 45227</a></p>
            </div>
        </div>

        <hr className="mt-16 mb-10 border-gray-200" />

        <p className="text-sm text-center text-gray-600">© Copyright 2025, All Rights Reserved by Smler</p>
    </div>
</section>

    </>
  )
}